import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import BackArrow from './BackArrow';

const Download = () => {
  return (
    <div>
            <BackArrow />
     <div className="download-page" style={{ textAlign: 'center', padding: '20px' }}>
      <h2>Download Our App</h2>
      <p>
        Choose your preferred platform to download the app:
      </p>
      <div className="download-links" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <a href="https://play.google.com/store/apps/details?id=com.chitraan" target="_blank" rel="noopener noreferrer">
          <img src="/assets/play-store.svg" alt="Get it on Google Play" className="store-logo" />
        </a>
        <a href="https://apps.apple.com/us/app/chitraan/id6670152772" target="_blank" rel="noopener noreferrer">
          <img src="/assets/app-store.svg" alt="Download on the App Store" className="store-logo" />
        </a>
      </div>
      
    </div>
      <footer className="footer">
        <Link to="/privacypolicy" >
          Privacy Policy
        </Link>
      </footer>
    </div>
  );
};

export default Download;

