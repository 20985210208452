import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import BackArrow from './BackArrow';

const Home = () => {
  return (
    <div>
      <div className="hero">
        <h2>Put overthinking to great use</h2>
        <span>
          Start writing things down; tag them by people, events, actions, feelings, location, activity.
          <br />
          Don't just let things be in your head.
          <br />
          Go over them when you have the time.
        </span>
        <div className="download-link">
          <Link to="/download" className="download-button">
            Clear my mind
          </Link>
        </div>
      </div>

      <div className="screenshots">
        <img src={require('../assets/chitraan_ss.png')} alt="Screenshot 1" />
        {/* <img src={require('../assets/IMG_6947.PNG')} alt="Screenshot 2" />
        <img src={require('../assets/IMG_6948.PNG')} alt="Screenshot 4" />
        <img src={require('../assets/IMG_6949.PNG')} alt="Screenshot 5" />
        <img src={require('../assets/IMG_6950.PNG')} alt="Screenshot 6" /> */}
      </div>

      <footer className="footer">
        <ul>
          <li>
            <Link to="/privacypolicy">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/support">
              Support
            </Link>
          </li>
          <li>
            <Link to="/mobile/changelog">
              Changelog
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Home;
