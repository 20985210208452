import React, { useState } from 'react';
import BackArrow from './BackArrow';
import './Faqs.css'; // Ensure to create and import this CSS file for styling

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: 'What is Chitraan?',
      answer: 'Chitraan is a journaling and note-taking app designed to help you capture your thoughts, track your emotions, and organize your daily reflections.',
    },
    {
      question: 'How can I create an account?',
      answer: 'You can create an account by downloading the app from the App Store or Google Play Store and following the on-screen instructions to sign up using Apple or Google Sign in',
    },
    {
      question: 'Is my data secure?',
      answer: 'Yes, we use industry-standard encryption and security practices to protect your data. Your entries are stored securely and are only accessible to you.',
    },
    {
      question: 'What features does Chitraan offer?',
      answer: 'Chitraan offers features like tagging, searching by title or content, adding emotions and locations to entries, and the ability to categorize and organize your notes.',
    },
    {
      question: 'Can I customize my journaling experience?',
      answer: 'As of now, we have a limited writing board, but we are working on adding more writing templates and customization options in the future.',
    },
    {
      question: 'How do I add a new journal entry?',
      answer: 'To add a new entry, tap the “pen” button in the bottom right of the app, then write your thoughts, add tags, emotions, or locations, and save',
    },
    {
      question: 'Can I edit or delete my journal entries?',
      answer: 'Yes, you can edit or delete any journal entry by selecting the entry and choosing the appropriate option.',
    },
    {
      question: 'How do I search for specific entries?',
      answer: 'Use the search bar within the app to search by keywords, tags, titles, or content. You can also filter entries based on date, location, or emotions.',
    },
    {
      question: 'How do I delete my account?',
      answer: 'Account deletion can only be done through the app. Navigate to the account settings in the app, and select the option to delete your account. Follow the instructions to complete the process.',
    },
    {
      question: 'Can I export my journal entries?',
      answer: 'As of now, we do not have an export feature, but we are working on adding this functionality in the future.',
    },
    {
      question: 'How do I contact support?',
      answer: 'For any issues or support requests, you can contact us via the “Support” option in the app or email us directly at ankitmhatre@hotmail.com.',
    },
    {
      question: 'How do I provide feedback or suggest new features?',
      answer: 'You can provide feedback or suggest new features through the app’s feedback form or by emailing me at ankitmhatre@hotmail.com',
    }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs-container">
      <BackArrow />
      <h1>Frequently Asked Questions</h1>
      <div className="faqs-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleAccordion(index)}>
              <h3>{faq.question}</h3>
              <span className="toggle-icon">
                {activeIndex === index ? '-' : '+'}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
