import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackArrow.css'; // Optional: Create this file for styling

const BackArrow = () => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className="back-arrow">
      ← Back
    </button>
  );
};

export default BackArrow;
