import React from 'react';
import './UserGuide.css'; // Import CSS file for styling
import BackArrow from './BackArrow'; // Assuming you have a BackArrow component

const UserGuide = () => {
  return (
    <div className="user-guide-container">
      <BackArrow />
      <h1>User Guide</h1>

      <section className="guide-section">
        <h2>Introduction</h2>
        <p>
          Welcome to Chitraan! This guide will help you get started with using the app. Chitraan is designed to help you capture your thoughts, track your emotions, and organize your daily reflections.
        </p>
      </section>

      <section className="guide-section">
        <h2>Features</h2>
        <ul>
          <li>Tagging: Organize your entries by people, events, actions, feelings, and locations.</li>
          <li>Search: Find entries quickly using keywords, tags, titles, or content.</li>
          <li>Customization: Add emotions and locations to your entries for better organization.</li>
          <li>Secure Data: Your data is encrypted in transit and stored securely, accessible only by you.</li>
        </ul>
      </section>

      <section className="guide-section">
        <h2>How to Use the App</h2>
        <h3>Creating an Account</h3>
        <p>
          Download the app from the App Store or Google Play Store. Follow the on-screen instructions to sign up using Apple or Google Sign-In.
        </p>
        
        <h3>Adding a New Journal Entry</h3>
        <p>
          Tap the “pen” button in the bottom right corner of the app, write your thoughts, add tags, emotions, or locations, and save your entry.
        </p>

        <h3>Editing or Deleting Entries</h3>
        <p>
        <ul>
          <li>   To delete an entry, select the entry and choose the appropriate option.<br /></li>
          <li> we are working on edit feature.     </li> 
            </ul>
        </p>
      </section>

      <section className="guide-section">
        <h2>Contact Support</h2>
        <p>
          For any issues or support requests, contact us via the “Support” option in the app or email us directly at ankitmhatre@hotmail.com.
        </p>
      </section>
    </div>
  );
};

export default UserGuide;
