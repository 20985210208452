import React, { useEffect, useState } from 'react';
import BackArrow from './BackArrow';

const MobileChangelog = () => {
  const [changelog, setChangelog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the JSON data from the public folder
    fetch('/assets/mobilechangelog.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setChangelog(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching changelog:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading changelog: {error.message}</div>;
  }

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <center><h2>CHITRAAN</h2></center>
      <BackArrow />
      {changelog.map((entry, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <p>
            <strong>{entry.version}</strong>
            <br />
            <span style={{ fontSize: '10pt' }}>{entry.date}</span>
          </p>
          <ol>
            {entry.changes.map((change, idx) => (
              <li key={idx}>{change}</li>
            ))}
          </ol>
        </div>
      ))}
    </div>
  );
};

export default MobileChangelog;
