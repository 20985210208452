import React from 'react';
import BackArrow from './BackArrow';

const DeleteAccount = () => {
  return (
    <div>


    <center>
    <BackArrow />
      <h1>Delete Account</h1>
      <p>Please note: Account deletion can only be performed through the Chitraan app. You cannot delete your account through this page.</p>
      <h2>How to Delete Your Account</h2>
      <p>To delete your account, go to the settings screen in the Chitraan app. Here’s what it looks like:</p>
      <img
        src={require('../assets/delete_screen.jpeg')}
        alt="Chitraan App Settings Screen"
        style={{ width: '20%', maxWidth: '200px', border: '1px solid #ddd', borderRadius: '8px', padding: '10px' }}
      />
    </center>
    </div>
  );
};

export default DeleteAccount;
