import React from 'react';
import BackArrow from './BackArrow';

const PrivacyPolicy = () => (
  <div style={{
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
   
  }}>

  <BackArrow />
    <h1>Privacy Policy</h1>
<h6 style={{
  color: 'gray',
}}>Last updated on 27 August, 2024</h6>
    <h2>Introduction</h2>
    <p>
     <h4> Welcome to Chitraan</h4>

       We are dedicated to protecting your personal information and your right to privacy. This Privacy Policy explains what information we collect, how we use it, and what rights you have in relation to it. By using our app, you agree to the terms of this policy.
    </p>

    <h2>Information We Collect</h2>
    <h3>1. Information You Provide to Us</h3>
    <h4>For Logged In Users:</h4>
    
    <p>
      <strong>Google Account Details:</strong> When you log in, we collect your Google account details (such as your name, email address, and profile picture link). This information is used solely for the purposes of authenticating you as and allowing you to upload interact with the content personal to you.
    </p>

    <p>
      <strong>Apple Account Details:</strong> When you log in, we collect your Apple account details (such as your name, email address only). This information is used solely for the purposes of authenticating you as and allowing you to upload interact with the content personal to you.
    </p>

    <p>
      <strong>Content Uploads:</strong> When you create a note or add any other media like images, videos, or audio files, we collect this content and store in our secure database, to show it later back to you.
    </p>
    <p>
      <strong>Article Analytics:</strong> We process basic analytics on the information you have provided to us to provide you for your own performance analytics. We do not use/read any of your data for any other reason.
    </p>

    <h3>2. Information Collected Automatically</h3>
    <p>
      <strong>Usage Data:</strong> We automatically collect certain information when you use the app. This information may include your device type, operating system, app version, and usage statistics (e.g., time spent on pages). None of the these information is collected in a targetted manner.
    </p>

    <h3>3. Location Data</h3>
    <p>
       To tag the articles on a map, we do need location services, we collect your location data with your consent. If you do not consent, you can opt-out of sharing your location at any time in your device settings.
    </p>

    <h3>4. Cookies and Similar Technologies</h3>
    <p>
      <strong>Cookies:</strong> We absolutely do not use cookies. Neither do we use any third party SDK's which function on Cookies.
    </p>

    <h2>How We Use Your Information</h2>
    <p>We use the information we collect in the following ways:</p>
    <ul>
      <li><strong>To Provide and Maintain Our Service:</strong> Including monitoring the usage of our app.</li>
      <li><strong>To Manage Your Account:</strong> To manage your registration as a user.</li>
      <li><strong>To Facilitate Content Upload:</strong> Allowing the user to upload articles, images, videos, and audio files.</li>
      <li><strong>To Provide Analytics:</strong> Showing users the counts of their articles.</li>
      <li><strong>To Provide Location-Based Services:</strong> Displaying locations on the notes tagged.</li>
   
      <li><strong>To Improve Our Service:</strong> Understanding how users interact with our app to enhance functionality and user experience.</li>
    </ul>

    <h2>Data Sharing and Disclosure</h2>
    <p>We do not share your personal information with third parties in any manner whatsoever till data, until we make changes to the privacy policy</p>
    

    <h2>Data Retention</h2>
    <p>
      <strong>Retention Period:</strong> We retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy.
    </p>
    <p>
      <strong>Account Deletion:</strong> Users can request to delete their accounts or any retained data at any time by contacting us. Once requested, we will promptly delete the data.
      <h3>  You can request to delete your account <a href="https://chitraan.com/deleteAccount">here</a></h3>
    </p>

    <h2>Data Security</h2>
    <p>
      We take reasonable measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no security measures are perfect or impenetrable.
    </p>

    <h2>Your Data Protection Rights</h2>
    <p>Depending on your location, you may have the following rights:</p>
    <ul>
      <li><strong>Access:</strong> You have the right to request copies of your personal data.</li>
      <li><strong>Rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
      <li><strong>Deletion:</strong> You have the right to request that we erase your personal data, under certain conditions.</li>
      <li><strong>Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
      <li><strong>Object to Processing:</strong> You have the right to object to our processing of your personal data, under certain conditions.</li>
      <li><strong>Data Portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
    </ul>

    <p>
      To exercise any of these rights, please contact us using the contact details provided below. We will respond to your request within a reasonable timeframe and in accordance with applicable laws.
    </p>

    <h2>Children's Privacy</h2>
    <p>
      We does not knowingly collect or solicit personal information from children under the age of 13. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us with personal information, please contact us at the email address provided below.
    </p>

    <h2>Changes to This Privacy Policy</h2>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
    </p>

    <h2>Contact Me</h2>
    <p>
      If you have any questions or concerns about this Privacy Policy, please contact me at:
    </p>
    <p>
    <strong>Best,</strong><br />
      Ankit Mhatre<br/>
     ankitmhatre@hotmail.com
    </p>
  </div>
);

export default PrivacyPolicy;