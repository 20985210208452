import React from 'react';
import { Link } from 'react-router-dom';
import BackArrow from './BackArrow';

const Support = () => {
    const options = [
        {
            icon: '👤', // Replace with an actual icon or image if available
            title: 'Contact Info',
            message: 'For support, please contact me directly.',
            linkText: 'Email Ankit Mhatre',
            link: 'mailto:ankitmhatre@hotmail.com',
        },
        {
            icon: '❓', // Replace with an actual icon or image if available
            title: 'FAQs',
            message: 'Find answers to common questions about using the Chitraan app.',
            linkText: 'View FAQs',
            link: '/support/faqs', // Replace with the actual link
        },
        {
            icon: '📖', // Replace with an actual icon or image if available
            title: 'User Guide',
            message: 'Learn how to use all the features of Chitraan effectively.',
            linkText: 'Read the User Guide',
            link: '/support/user-guide', // Replace with the actual link
        },
        {
            icon: '🔐', // Replace with an actual icon or image if available
            title: 'Privacy Policy',
            message: 'Understand how we protect your data and privacy.',
            linkText: 'View Privacy Policy',
            link: '/privacypolicy', // Replace with the actual link
        },
        {
            icon: '💬', // Replace with an actual icon or image if available
            title: 'Feedback',
            message: 'We’d love to hear your thoughts and suggestions!',
            linkText: 'Send Feedback',
            link: 'mailto:ankitmhatre@hotmail.com?subject=Feedback for Chitraan App',
        },
        // {
        //     icon: '🚀', // Replace with an actual icon or image if available
        //     title: 'Beta Testing',
        //     message: 'Interested in helping us improve? Join our beta testing program!',
        //     linkText: 'Enroll in Beta Testing',
        //     link: '/beta', // Replace with the actual link
        // },
        {
            icon: '🗑️', // Replace with an actual icon or image if available
            title: 'Account Deletion',
            message: 'If you wish to permanently delete your account.',
            linkText: 'Delete Account',
            link: '/deleteAccount', // Replace with the actual link
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
                  <BackArrow />
            <h1 style={{ textAlign: 'center' }}>Support</h1>
            <p style={{ textAlign: 'center' }}>Welcome to the Chitraan Support page!</p>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                {options.map((option, index) => (
                    <div key={index} style={{ textAlign: 'center', width: '30%', margin: '10px', padding: '20px', border: '1px solid #ddd', borderRadius: '10px' }}>
                        <div style={{ fontSize: '40px', marginBottom: '10px' }}>{option.icon}</div>
                        <h3>{option.title}</h3>
                        <p>{option.message}</p>
                        <Link to={option.link} style={{ textDecoration: 'none', color: '#007bff' }}>{option.linkText} &gt;</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Support;
